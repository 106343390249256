import { Grid, Typography, Link } from "@mui/material";
import { ArtistCommunityCard } from "components/cards";
import { styled } from "@mui/material/styles";
import { ArtistProps } from "@types";

const ContentStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2, 10),
}));

type ArtistListProps = {
  artists: ArtistProps[];
};

export default function ArtistList({ artists }: ArtistListProps) {
  return (
    <ContentStyle>
      <Typography
        variant="h4"
        sx={{
          color: "common.black",
          textTransform: "uppercase",
          fontWeight: "bold",
          // fontFamily: "Dela Gothic One, regular",
        }}
      >
        Search Result
      </Typography>
      <Grid container spacing={2} sx={{ my: 5, justifyContent: "center", width: "100%" }}>
        {artists.length > 0 ? (
          artists.map((artist) => (
            <Grid item key={artist._id}>
              <Link href={`/book/${artist.username}`} >
                <ArtistCommunityCard
                  _id={artist._id}
                  avatarImg={artist.avatarImg}
                  username={artist.username}
                />
              </Link>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            No matching artists found
          </Typography>
        )}
      </Grid>
    </ContentStyle>
  );
}
